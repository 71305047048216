<template>
  <div class="go-container">
    <template v-for="o in orari">
      <div
        class="go-row"
        :class="{
          quarter: formatHour(o).indexOf(':00') === -1 && formatHour(o).indexOf(':30') === -1,
          ferie: ferie ? ferie.find(f => o > f.oraInizio && o < f.oraFine) : false
        }"
        :style="{ height: rowHeight + 'px' }"
        :key="o + 'qt'"
      ></div>
    </template>
    <div
      v-for="p in prenotazioni"
      :key="p.id"
      class="go-item"
      :style="{
        top: calcPosition(fnPosition(p)) + 'px',
        height: calcHeight(p.orarioFinePrevisto - p.orarioInizioPrevisto) + 'px'
      }"
    >
      <slot name="prenotazione" v-bind:prenotazione="p"></slot>
    </div>
    <template v-if="ferie && ferie.length > 0">
      <div
        v-for="f in ferie"
        :key="f.id"
        class="go-item"
        :style="{
          top: f.oraInizio == null ? '0' : calcPosition(f.oraInizio) + 'px',
          height: f.oraFine == null ? '100%' : calcHeight(f.oraFine - f.oraInizio) + 'px'
        }"
      >
        <div class="cardFerie"></div>
      </div>
    </template>
  </div>
</template>

<script>
import utils from "../../utils";

export default {
  props: ["orari", "rowHeight", "prenotazioni", "data", "fnPosition", "fnHeight", "ferie"],
  computed: {
    start() {
      return this.orari[0];
    },
    end() {
      return this.orari[this.orari.length - 1];
    },
    rangeOrariSorted(){
      let a = this.rangeOrari
      return a.sort(el => el.orarioInizio).reverse();
    },
    spaziBarrati() {
      if(this.rangeOrari.length > 0){
        let barrati = [];
        for(let i = 0; i <= this.rangeOrariSorted.length; i++){
          if(i == 0){//prima operazione
            this.$set(barrati, i, {
                top: this.calcPosition(this.rangeOrariSorted[i].orarioFine) + "px",
                height: this.calcHeight(this.end - this.rangeOrariSorted[i].orarioFine) + "px"
              })
          }
          else if(i == this.rangeOrariSorted.length){//ultima oprezione
            this.$set(barrati, i, {
                top: "0",
                height: this.calcHeight(this.rangeOrariSorted[i -1].orarioInizio - this.start) + "px"
              })
          }
          else{//operazioni di mezzo, dunque differenze tra range orari per trovare gli spazi da barrare
            this.$set(barrati, i, {
                top: this.calcPosition(this.rangeOrariSorted[i].orarioFine) + "px",
               height: this.calcHeight(this.rangeOrariSorted[i -1].orarioInizio - this.rangeOrariSorted[i].orarioFine) + "px"
            })
          }
        }
        return barrati;
      }else return null;
    }
  },
  methods: {
    calcPosition(p) {
      for (let i = 0; i < this.orari.length; i++) {
        if (this.orari[i] > p) return (i - 1) * this.rowHeight;
      }
      return (this.orari.length - 1) * this.rowHeight;
    },
    formatHour(h) {
      return utils.parseOrario(h);
    },
    calcHeight(value) {
      if (this.fnHeight) value = this.fnHeight(value);
      return (value / (this.orari[1] - this.orari[0])) * this.rowHeight;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.go-container {
  position: relative;
  border-left: solid 1px darken($calendar-border-color, 15%);
  @media (prefers-color-scheme: dark) {
    border-color: darken($calendar-border-color, 70%);
  }
}

.go-item {
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  padding: 0 5px;
}
.go-row {
  padding-left: 1em;
  margin-left: -1em;
  box-shadow: 0px -1px 0 darken($calendar-border-color, 10%);
  @media (prefers-color-scheme: dark) {
    box-shadow: 0px -1px 0 darken($calendar-border-color, 70%);
  }
  &.quarter {
    box-shadow: 0px -1px 0 $calendar-border-color;
    @media (prefers-color-scheme: dark) {
      box-shadow: 0px -1px 0 darken($calendar-border-color, 80%);
    }
  }
  &.ferie {
    box-shadow: none;
  }
}

.cardFerie {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, rgba(0,0,0,.1) 10px, rgba(0,0,0,.1) 20px);
  width: 100%;
  height: 100%;
  pointer-events: none;
  position: relative;
  z-index: 1;
  @media (prefers-color-scheme: dark) {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, #FFF1 10px, #FFF1 20px);
  }
}
</style>
