<template>
  <transition name="side" mode="out-in">
    <div
      ref="side"
      class="sidebar"
      :class="{mobile: wWidth <= 768, shown: $root.sidebarShown}"
      v-show="$root.sidebarShown"
    >
      <div class="side-top">
        <button class="btn round transparent" @click="hide()">
          <i class="m-hide fa fa-arrow-right"></i>
          <i class="m-show fa fa-chevron-down"></i>
        </button>
        <h2>{{title}}</h2>
      </div>
      <div class="side-btm">
        <component v-if="component" :is="component" v-bind="componentData"></component>
      </div>
    </div>
  </transition>
</template>

<script>
// import Hammer from "hammerjs";


export default {
  data() {
    return {
      component: null,
      componentData: null,
      title: "",
      wWidth: window.innerWidth,
      lastPosY: 0,
      isDragging: false

    };
  },
  mounted() {
    /*if (this.$refs.side) {
      var hammertime = new Hammer(this.$refs.side);
      hammertime.add(
        new Hammer.Pan({ direction: Hammer.DIRECTION_ALL, threshold: 0 })
      );
      hammertime.on("pan", ev => this.handleDrag(ev, this.$refs.side));
    } */
  },
  created() {
    window.addEventListener("resize", () => {
      this.wWidth = window.innerWidth;
      if (this.wWidth > 768) {
        this.$refs.side.style.top = "";
        this.$refs.side.style.transition = "";
      }
    });
    this.$root.$on("sidebar", async k => {
      if (k.component) this.component = k.component;
      if (!this.component) return;
      this.$router
        .push({ path: this.$route.path, query: { sidebar: true } })
        .catch(() => {});
      this.componentData = k.componentData;
      if (k.title) this.title = k.title;
      this.$root.sidebarShown = true;
    });
    this.$root.$on("hideSidebar", async () => {
      this.component = null;
      this.componentData = null;
      // await this.$router.go(-1);
      this.$root.sidebarShown = false;
    });
  },
  methods: {
    hide() {
      this.$root.$emit("hideSidebar");
      setTimeout(() => {
        this.$refs.side.style.transform = "";
      }, 500);
    },

    handleDrag(ev, elem) {
      if (this.wWidth > 768) return;
      if (!elem) return;

      // DRAG STARTED
      // here, let's snag the current position
      // and keep track of the fact that we're dragging
      if (!this.isDragging) {
        this.isDragging = true;
        // lastPosX = elem.offsetLeft;
        this.lastPosY = elem.offsetTop;
        elem.style.transition = "";
      }

      if (ev.deltaY < 0) return

      // we simply need to determine where the x,y of this
      // object is relative to where it's "last" known position is
      // NOTE:
      //    deltaX and deltaY are cumulative
      // Thus we need to always calculate 'real x and y' relative
      // to the "lastPosX/Y"
      // var posX = ev.deltaX + lastPosX;
      var posY = ev.deltaY + this.lastPosY;


      // move our element to that position
      // elem.style.left = posX + "px";
      // console.log(posY)
      elem.style.transform = `translateX(-50%) translateY(${ev.deltaY}px)`;

      // DRAG ENDED
      // this is where we simply forget we are dragging
      if (ev.isFinal) {
        // console.log(ev)
        this.isDragging = false;
        elem.style.transition = ".5s cubic-bezier(.19,1,.22,1)";
        if (posY > 500) {
          elem.style.transform = "translateX(-50%) translateY(110%)";
          this.hide()
        } else {
          elem.style.transform = ""
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/variables.scss";

.sidebar {
  background-color: white;
  @media (prefers-color-scheme: dark) {
    background-color: #111111;
  }
  box-sizing: border-box;
  position: relative;
  width: 500px;
  // transition: .4s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 0;
  padding-right: env(safe-area-inset-right);
  display: flex;
  will-change: transform;
  flex-direction: column;
  overflow: hidden;
  .side-top {
    height: 48px;
    background-color: #fafafa;
    @media (prefers-color-scheme: dark) {
      background-color: #111111;
    }
    display: flex;
    align-items: center;
    padding: 1em;
    @media screen and (min-width: 769px) {
      padding-top: calc(1em + env(safe-area-inset-top));
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  .side-btm {
    padding: 0;
    box-sizing: border-box;
    height: 100%;
    overflow-y: auto;
  }
  @media screen and (min-width: 769px) {
    border-left: 1px solid rgba(0,0,0,.1);
    transition: .4s cubic-bezier(0.19, 1, 0.22, 1);
    position: absolute;
    height: 100%;
    width: 420px;
    display: flex!important;
    z-index: 9;
    bottom:0;
    right: 0;
    box-shadow: -3px 0 25px rgba(0,0,0,.1);
    transform: translateX(100%);
    &.shown {
      transform: translateX(0);
    }
  }
  @media screen and (max-width: 768px) {
    position: absolute;
    width: 100%;
    z-index: 99;
    min-height: 300px;
    max-width: 500px;
    height: calc(100% - env(safe-area-inset-top) - 1em);
    transition: .7s cubic-bezier(0.19, 1, 0.22, 1);
    box-sizing: border-box;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    /*&.shown {
          transform: translatex(-50%) translateY(0);
        }*/
    border-radius: 12px 12px 0 0;
    // box-shadow: $card-shadow-top;
  }
}

.black {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  z-index: 9;
}
@media screen and (max-width: 768px) {
.side-enter-active,
.side-leave-active {

  transition: 0.7s cubic-bezier(0.19, 1, 0.22, 1);
}
.side-enter,
.side-leave-to {
  //transform: translateX(100%);
  //@media screen and (max-width: 768px) {
    transform: translatex(-50%) translateY(110%);
  //}
}
}
</style>
