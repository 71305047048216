<template>
    <div class="cardPrenotazione prenotazione flex justify-center" @click="apriSidebar" :class="{muted:!prenotazione.orarioFineEffettivo, complete: prenotazione.orarioFineEffettivo && prenotazione.importo !== null && prenotazione.importo !== undefined && !prenotazione.pagato,payed: prenotazione.pagato}">
        <div class="left m-show">
          {{formatta(prenotazione.orarioInizioPrevisto)}}
        </div>
        <div class="flex column justify-center right">
          <b id="p-name">{{prenotazione.utente.nome}} {{prenotazione.utente.cognome}}  <vue-feather type="bell" size="16" v-if="notificaNote" style="display:inline-block;vertical-align: middle;" /></b>
          <span>{{prenotazione.servizi.map(m => m.nome).join(',')}}</span>
          <b v-if="prenotazione.orarioFineEffettivo && prenotazione.importo && !prenotazione.pagato">DA PAGARE</b>
          <b v-else-if="iniziata">IN CORSO</b>


        </div>
    </div>
</template>

<script>
import CardPrenotazione from '../../common/components/EditPrenotazione.vue';
import VueFeather from 'vue-feather';
import utils from '../../utils';

export default {
  props: ['prenotazione', 'data', 'orario'],
  methods: {
    apriSidebar() {
      this.$root.$emit('sidebar', { component: CardPrenotazione, componentData: { prenotazione: this.prenotazione, sidebar: true }, title: "Prenota" });
    },
    formatta(x) {
      return utils.parseOrario(x);
    },
  },
  computed: {
    iniziata() {
      return this.prenotazione.orarioInizioEffettivo !== null && this.prenotazione.orarioInizioEffettivo !== undefined && !this.prenotazione.orarioFineEffettivo;
    },
    importoImpostato() {
      return this.prenotazione.importo !== null && this.prenotazione.importo !== undefined;
    },
    notificaNote(){
      return this.prenotazione.note !== null && this.prenotazione.note.some(n => n.tipo === "Segreteria" && !n.notificata)
    }
  },
  components: {
    VueFeather
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
  .prenotazione{


        @media screen and (min-width: 769px) {
          // @include card-interactive;
          box-shadow: none;
          height: 100%;
          padding: 2em 1em;


          .right {
            border: none;
            margin-top: 0;
            padding: 0
          }
        }


  }
</style>
