<template>
  <div class="new-pren">
    <template v-if="!showConfirmation && !isDone">
      <div class="top" ref="cont">
        <div v-if="$root.parrucchiere || $root.segreteria">
          <span class="label" style="display: block;margin-top:0; margin-bottom: 1em">Utente</span>
          <vue-select
            placeholder="Scegli un utente..."
            @search="cercaUtente"
            transition="none"
            :options="this.utenti"
            :reduce="u => u.id"
            @input="v => (input.idUtente = v)"
            :filterable="false"
            label="nomeCompleto"
          >
            <template v-slot:option="option">
              <li v-if="option.id === 0" @mousedown.prevent="creaUtente(option.nomeCompleto)">
                Crea "{{ option.nomeCompleto }}"
              </li>

              <li v-else>
                <img
                  class="p-photo"
                  v-if="option.haFoto"
                  :src="'/api/Staff/Utente/Immagine?id=' + option.id"
                />{{ option.nomeCompleto }}<br /><span>{{ option.telefono }}</span>
              </li>
            </template>
            <template v-slot:no-options><span class="label">Cerca un utente...</span></template>
          </vue-select>
        </div>

        <template>
          <div
            class="flex justify-between label"
            :style="$root.parrucchiere || $root.segreteria ? '' : 'margin-top:0'"
          >
            Scegli una categoria <a v-if="step > 2" @click="step = 2">Mostra tutto</a>
          </div>
          <div class="servizi">
            <div class="cat-select-container">
              <template v-for="(categoria, i) in alberoServizi">
                <div
                  @click="accCategory = accCategory != categoria.id ? categoria.id : null"
                  class="cat-select"
                  :class="{ active: accCategory === categoria.id, barb: categoria.id === 1, est: categoria.id === 2, mass: categoria.id === 3}"
                  :key="'cat' + i"
                >
                  {{ categoria.nome }}

                </div>
              </template>
            </div>

            <template v-for="(categoria, i) in alberoServizi">
              <!--transition
                name="accordion"
                @enter="accordionStart"
                :key="'cata' + i"
                @after-enter="accordionEnd"
                @before-leave="accordionStart"
                @after-leave="accordionEnd"
               -->
              <div v-show="accCategory === categoria.id" :key="'cata' + i">
                <template v-for="(s, i) in categoria.servizi">
                  <div
                    class="s-cont"
                    v-if="(input.idServizi.indexOf(s.id) > -1 && step !== 2) || step <= 2"
                    :key="i"
                  >
                    <input
                      class="h-input"
                      type="radio"
                      v-model="input.idServizi"
                      @input="aggiornaOrari()"
                      :value="[s.id]"
                      :id="'serv-' + s.id"
                    />
                    <label
                      :for="'serv-' + s.id"
                      :class="{
                        exp: input.idServizi.indexOf(s.id) > -1,
                        noPadding: $root.parrucchiere
                      }"
                      class="servizio s-label"
                    >
                      <span>&nbsp;{{ s.nome }}</span>

                      <template v-if="input.idServizi.indexOf(s.id) > -1">
                        <small id="s-desc" v-html="s.descrizione"></small>
                        <p
                          class="alert"
                          style="display: block;padding: 1em;"
                          v-if="serviziNonPrenotabili.length > 0"
                        >
                          <small
                            >Il servizio {{ serviziNonPrenotabili.map(m => m.nome).join(",") }} 
                            non è prenotabile tramite app. Contattaci per avere informazioni.</small>
                        </p>
                        <div v-else-if="!$root.parrucchiere && input.idServizi.length > 0">
                          <div class="label">Parrucchiere</div>
                          <div class="p-barber-cont">
                            <!--div class="p-barber" @click="input.idParrucchiere = null;">
                  <div class="p-label" :class="{selected: input.idParrucchiere === null}"><img class="p-photo" src="/img/icons/default-user.png">Qualsiasi</div>
                    </div-->

                            <div
                              class="p-barber"
                              v-for="p in parrucchieri.filter(p =>
                                p.servizi.find(x => x.id == s.id)
                              )"
                              :key="p.id"
                              @click="step = 3"
                            >
                              <input
                                type="radio"
                                class="p-input"
                                v-model="input.idParrucchiere"
                                :value="p.id"
                                :id="'parr-' + p.id"
                                :disabled="p.disattivato"
                              />
                              <label
                                class="p-label"
                                :for="'parr-' + p.id"
                                :class="{ disabled: p.disattivato }"
                              >
                                <img v-if="p.haFoto" class="p-photo" :src="p.blobImg" />
                                <div class="p-photo p-placeholder" v-else>
                                  {{ p.nome.substr(0, 1) }}
                                </div>
                                {{ p.nome }}
                              </label>
                            </div>
                          </div>
                        </div>
                      </template>
                    </label>
                  </div>
                </template>
              </div>
              <!-- /transition -->
            </template>
          </div>
        </template>

        <div v-if="input.idServizi.length > 0 && serviziNonPrenotabili.length === 0">
          <div class="label">Quando?</div>
          <span
            id="selDate"
            v-if="step != 3"
            @click="
              step = 3;
              scrollToBottom();
            "
            >{{
              input.dataPrenotazione
                ? format(input.dataPrenotazione, "dd MMMM yyyy", { locale: it })
                : "Seleziona una data"
            }}</span
          >
          <date-picker
            :min-page="{ month: first.getMonth() + 1, year: first.getFullYear() }"
            :max-page="{ month: last.getMonth() + 1, year: last.getFullYear() }"
            :available-dates="availableDates"
            navVisibility="hidden"
            v-show="step == 3"
            is-expanded
            is-inline
            mode="single"
            :value="input.dataPrenotazione"
            @input="
              v => {
                input.dataPrenotazione = v;
                aggiornaOrari(scrollToBottom);
                calExp = false;
                step++;
              }
            "
          />

          <i style="display:block;padding: 1em;" v-if="serviziNonPrenotabili.length > 1"
            >I servizi {{ serviziNonPrenotabili.map(m => m.nome).join(",") }} non sono prenotabili.
            Passa quando vuoi</i
          >
        </div>
        <div v-if="orari.length > 0 && !calExp">
          <div class="label">A che ora?</div>

          <div class="orari">
            <div
              style="display:inline-block"
              v-for="o in orari.filter(o =>
                isToday(input.dataPrenotazione) ? o > oraCorrente : true
              )"
              :key="o"
            >
              <input class="h-input" type="radio" v-model="input.orarioInizio" :value="o" :id="o" />
              <label :for="o" class="orario h-label">{{ parseOrario(o) }}</label>
            </div>
          </div>
        </div>
        <div class="label" v-else-if="input.dataPrenotazione && aggiornamentoOrari">
          Caricamento...
        </div>
        <div class="label" v-else-if="input.dataPrenotazione && !aggiornamentoOrari">
          <span
            >Oops, {{ $root.parrucchieri.find(x => x.id === input.idParrucchiere).nome }} per questa
            data non ha più disponibilità. Prova a selezionare un altro giorno o un altro
            parrucchiere</span
          >
        </div>
      </div>

      <div class="btm">
        <button
          :disabled="
            !input.idServizi ||
              !input.dataPrenotazione ||
              !input.orarioInizio ||
              (($root.parrucchiere || $root.segreteria) && !input.idUtente)
          "
          class="btn btn-lg w-100 active"
          @click="crea($root.parrucchiere || $root.segreteria ? false : true)"
        >
          {{ $root.parrucchiere || $root.segreteria ? "Prenota" : "Continua" }} &nbsp;
          <i class="fas fa-spin fa-spinner" v-if="loading"></i>
        </button>
      </div>
    </template>

    <template v-else-if="showConfirmation && !isDone">
      <div class="top">
        <div class="top flex column align-center justify-center">
          <div class="p-barber">
            <label
              class="p-label"
              :for="'parr-' + parrucchieri.find(p => p.id === input.idParrucchiere).id"
            >
              <img
                style="width: 64px; height: 64px"
                v-if="parrucchieri.find(p => p.id === input.idParrucchiere).haFoto"
                class="p-photo"
                :src="parrucchieri.find(p => p.id === input.idParrucchiere).blobImg"
              />
              <div class="p-photo p-placeholder" v-else>
                {{ parrucchieri.find(p => p.id === input.idParrucchiere).nome.substr(0, 1) }}
              </div>
              {{ parrucchieri.find(p => p.id === input.idParrucchiere).nome }}
            </label>
          </div>

          <h1>{{ $root.servizi.find(s => s.id === input.idServizi[0]).nome }}</h1>
          <span class="label" id="details" style="margin-top:.5em"
            >il {{ format(input.dataPrenotazione, "dd MMMM yyyy", { locale: it }) }} alle
            {{ parseOrario(input.orarioInizio) }}</span
          >
        </div>
      </div>

      <div class="btm">
        <button
          :disabled="
            !input.idServizi ||
              !input.dataPrenotazione ||
              !input.orarioInizio ||
              (($root.parrucchiere || $root.segreteria) && !input.idUtente)
          "
          class="btn btn-lg w-100 active"
          @click="crea(false)"
        >
          Conferma Prenotazione &nbsp;
          <i class="fas fa-spin fa-spinner" v-if="loading"></i>
        </button>
        <br />
        <button
          v-if="!$root.parrucchiere && !$root.segreteria"
          class="btn btn-lg transparent text-center w-100"
          @click="showConfirmation = false"
        >
          Indietro
        </button>
      </div>
    </template>
    <template v-if="isDone">
      <div class="top">
        <div class="top flex column align-center justify-center">
          <i class="fas fa-check-circle" id="confirm-icon"></i>
          <h1 style="text-align: center;">Prenotazione confermata!</h1>
          <br />
          <AddCalendar
          v-if="$root.utente"
          :nomeServizio="$root.servizi.find(s => s.id === input.idServizi[0]).nome"
          :data="input.dataPrenotazione"
          :orario="parseOrario(input.orarioInizio)"
          :orarioFine="parseOrario(orarioFine)"
          :nomeParrucchiere="parrucchieri.find(p => p.id === input.idParrucchiere).nome"
          >
          </AddCalendar>
        </div>
      </div>
      <div class="btm">
        <button class="btn btn-lg text-center w-100 active" @click="$root.$emit('hideSidebar');">Finito</button>
      </div>
    </template>
  </div>
</template>

<script>
import vueSelect from "vue-select";
import ky from "../../network";
import utils from "../../utils";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { it } from "date-fns/locale";
import { isBefore, isToday, format } from "date-fns";
import smoothscroll from "smoothscroll-polyfill";
import AddCalendar from "@/utente/components/AddCalendar.vue";

smoothscroll.polyfill();

export default {
  components: {
    DatePicker,
    vueSelect,
    AddCalendar
  },
  data() {
    return {
      input: {
        idUtente: null,
        idParrucchiere: null,
        dataPrenotazione: null,
        idServizi: [],
        orarioInizio: null
      },
      orarioFine: null,
      it,
      showConfirmation: false,
      accCategory: null,
      // itCal,
      step: 1,
      utenti: [],
      matriceOrari: [],
      parseOrario: utils.parseOrario,
      apiPrefix: this.$root.utente ? "/api/Utente/" : "/api/Staff/",
      loading: false,

      utc: utils.UTC,
      first: new Date(this.$root.calendario[0]),
      last: new Date(this.$root.calendario[this.$root.calendario.length - 1]),
      aggiornamentoOrari: false,
      isDone: false
    };
  },

  mounted() {
    // console.log(this.availableDates)
  },

  computed: {
    oraCorrente() {
      const p = new Date();
      return p.getHours() * 3600 + p.getMinutes() * 60;
    },
    availableDates() {
      return this.$root.calendario.filter(
        d => !isBefore(utils.UTC(new Date(d)), utils.UTC(new Date()))
      );
    },
    parrucchieri() {
      if (this.$root.parrucchiere) {
        let p = JSON.parse(JSON.stringify(this.$root.parrucchiere));
        p.disattivato = false;
        return [p];
      } else {
        let parrucchieri = JSON.parse(JSON.stringify(this.$root.parrucchieri));
        parrucchieri.forEach(p => (p.disattivato = this.disattiva(p)));
        return parrucchieri;
      }
    },
    orari() {
      if (this.matriceOrari.length == 0) return [];
      if (this.input.idParrucchiere) {
        return this.matriceOrari.find(x => x.parrucchiere.id == this.input.idParrucchiere)
          .orariDisponibili;
      } else {
        let orari = [];
        for (let m of this.matriceOrari) {
          for (let o of m.orariDisponibili) {
            if (orari.indexOf(o) === -1) orari.push(o);
          }
        }
        orari.sort();
        return orari;
      }
    },
    serviziNonPrenotabili() {
      return this.$root.servizi.filter(
        x => this.input.idServizi.indexOf(x.id) !== -1 && !x.prenotabile
      );
    },
    alberoServizi() {
      let categorie = {};
      let servSenzaCat = [];
      for (let s of this.$root.servizi) {
        if (s.categoria && !categorie[s.categoria.id])
          categorie[s.categoria.id] = { ...s.categoria, servizi: [] };
      }
      for (let s of this.$root.servizi) {
        if (s.categoria) categorie[s.categoria.id].servizi.push(s);
        else servSenzaCat.push(s);
      }
      if (servSenzaCat.length > 0) {
        categorie[0] = { id: 0, nome: "Altro", servizi: servSenzaCat, ordine: 99 };
      }
      let response = Object.values(categorie);
      for (let r of response) r.servizi.sort((a, b) => a.ordine - b.ordine);
      response.sort((a, b) => a.ordine - b.ordine);
      return response;
    }
  },
  methods: {
    format,
    isToday,
    accordionStart(el) {
      el.style.height = el.scrollHeight + "px";
    },
    accordionEnd(el) {
      el.style.height = "";
    },
    scrollToBottom() {
      setTimeout(() => {
        this.$refs.cont.scrollTo({
          left: 0,
          behavior: "smooth",
          top: this.$refs.cont.scrollHeight
        });
      }, 10);
    },
    async aggiornaOrari(cb) {
      this.aggiornamentoOrari = true;
      if (!this.input.dataPrenotazione || this.input.idServizi.length == 0) {
        this.matriceOrari = [];
        return;
      }
      this.input.dataPrenotazione = utils.UTC(this.input.dataPrenotazione);
      let searchParams = {
        timestamp: Math.floor(new Date(this.input.dataPrenotazione).getTime() / 1000),
        idServizi: this.input.idServizi.join(",")
      };
      if (this.parrucchiere && this.parrucchiere.id)
        searchParams.idParrucchiere = this.parrucchiere.id;
      const r = await ky.get(`${this.apiPrefix}Orario/MatriceOrari`, { searchParams }).json();
      this.matriceOrari = r.risultato;
      this.aggiornamentoOrari = false;
      if (cb) cb();
    },
    disattiva(p) {
      if (this.matriceOrari.length == 0) return false;
      let el = this.matriceOrari.find(x => x.parrucchiere.id == p.id);
      if (el === undefined) return true;
      if (el.orariDisponibili.length === 0) return true;
      if (!this.input.orarioInizio) return false;
      return el.orariDisponibili.indexOf(this.input.orarioInizio) === -1;
    },
    async crea(confirm = false) {
      if (!confirm) {
        this.loading = true;
        this.input.dataPrenotazione = utils.UTC(this.input.dataPrenotazione);
        try {
          const r = await ky
            .post(`${this.apiPrefix}Prenotazione/Crea`, { json: this.input })
            .json();
          if (r.status) this.$root.$emit("update");
          if (this.$root.utente) {
            let ps =  await ky.get("/api/Utente/Prenotazione").json();
            this.orarioFine = ps.prenotazioni.find(p => p.orarioInizioPrevisto == this.input.orarioInizio && (new Date(p.dataPrenotazione)).toLocaleDateString() == this.input.dataPrenotazione.toLocaleDateString()).orarioFinePrevisto;
          }
          this.isDone = true;
          this.loading = false;
        } catch (e) {
          this.loading = false;
          let err = await e.response.json();
          // console.log(JSON.stringify(err));
          this.$root.$emit("snackbar", { type: "error", message: err.error });
        }
      } else {
        this.showConfirmation = true;
      }
    },
    async cercaUtente(q, loading) {
      loading(true);
      this.utenti = (
        await ky.get("/api/Staff/Utente/Cerca", { searchParams: { nome: q } }).json()
      ).utenti;
      if (q !== "") this.utenti.push({ id: 0, nomeCompleto: q });
      loading(false);
    },
    async creaUtente(n) {
      // console.log('cu')
      const res = await ky.post("/api/Staff/Utente/Crea", { searchParams: { nome: n } }).json();
      if (res.status) {
        this.input.idUtente = res.utente.id;
      }
      this.$emit("search:blur");
    }
  }
};
</script>

<style lang="scss">
@import "@/style/style.scss";

.disabled {
  pointer-events: none;
  opacity: 0.5;
}
.calendario {
  width: 200px;
}

.p-barber-cont {
  display: flex;
  flex-wrap: wrap;
}
.p-barber {
  display: inline-block;
  margin-bottom: 1em;
}

.p-input {
  visibility: hidden;
  position: absolute;

  &:checked + .p-label:after {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
}

.orari {
  @media screen and (max-width: 768px) {
    display: flex;
    overflow-x: scroll;
    scroll-snap-type: x proximity;
    margin-bottom: 2em;
  }
}

.h-input {
  visibility: hidden;
  position: absolute;

  & + .orario {
    cursor: pointer;
  }
  &:checked + .orario {
    color: white;
    border-color: transparent;
    box-shadow: 3px 3px 6px rgba(185, 188, 193, 0.4);
    background: $accent-gradient;
    border: 1px solid $color-border;
    background-size: 105%;
    background-position: center;
  }
}

.p-label {
  position: relative;
  display: inline-flex;
  user-select: none;
  cursor: pointer;
  flex-direction: column;
  font-size: 12px;
  margin: 0 0.5em;
  width: 64px;
  align-items: center;
  justify-content: center;
  line-height: 1em;
  text-align: center;
  &.selected:after {
    opacity: 1;
    transform: translateX(-50%) scale(1);
  }
  &:after {
    content: "\f00c";
    font-family: "FontAwesome";
    line-height: 36px;
    color: white;
    background-color: transparentize(
      $color: (
        $color-accent
      ),
      $amount: 0.5
    );
    position: absolute;
    width: 36px;
    border-radius: 100%;
    height: 36px;
    top: 0;
    opacity: 0;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    left: 50%;
    transform: translateX(-50%) scale(0.6);
  }
}

.calendario {
  border: none !important;
  margin-right: 0;
  box-sizing: border-box;
  width: 100%;
  .cell.day:not(.blank) {
    border-radius: 50%;
    box-shadow: 3px 3px 6px rgba(185, 188, 193, 0.4);
    border: 1px solid #eee;
  }
}

.cat-select-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 1em;
  margin-bottom: 1em;

  .cat-select {
    line-height: 36px;
    border-radius: $card-radius;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    opacity: .5;
    background-image: linear-gradient(to top, #0005, #0005);
    background-position: center;
    background-repeat: no repeat;
    background-size: cover;
    color: white;

    &.barb {
      background-image: linear-gradient(to top, #0005, #0005), url("/img/icons/barber.jpg");
    }

    &.est {
      background-image: linear-gradient(to top, #0005, #0005), url("/img/icons/aesthetic.jpg");
    }

    &.mass {
      background-image: linear-gradient(to top, #0005, #0005), url("/img/icons/stone.jpg");
    }

    &:hover {
      opacity: 1;
      background-color: #0003;
      @media (prefers-color-scheme: dark) {
        background-color: #fff3;
      }
    }
    @media (prefers-color-scheme: dark) {
      color: #fff;
    }

    &.active {
      opacity: 1;
      background-color: #000;
      color: white;
      @media (prefers-color-scheme: dark) {
        background-color: #fff;
      }
    }
  }
}

.orari {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.orario,
.s-label {
  display: inline-block;
  margin: 0.2em;
  border: 1px solid #0003;
  @media (prefers-color-scheme: dark) {
    border-color: #fff3;
  }
  line-height: 40px;
  padding: 0 0.8em;
  border-radius: 20px;
  text-align: center;
}

.orario:hover {
  background-color: #fff3;
}

#s-desc {
  color: #0005;
  display: block;
  font-size: 14px;
  line-height: 14px;
  @media (prefers-color-scheme: dark) {
    color: #fff8;
  }
}

.servizi {
  flex-direction: column;
  overflow: auto;
  .s-cont {
    position: relative;
    .servizio {
      //display: flex;
      text-align: left;
      width: 100%;
      cursor: pointer;
      border: none;
      margin: 0.1em 0;
      color: #0008;
      min-height: 36px;
      border-radius: 5px;
      box-sizing: border-box;
      @media (prefers-color-scheme: dark) {
        color: #fff8;
      }
      &:hover {
        color: #000e;
        background-color: #0001;
        @media (prefers-color-scheme: dark) {
          background-color: #fff1;
          color: #fffe;
        }
      }
      &.exp {
        color: #000e;
        @media (prefers-color-scheme: dark) {
          color: #fffe;
        }
        cursor: default;
        padding-bottom: 1em;
        background-color: transparentize($color-teal, 0.8);
        border-left: 6px solid $color-teal;
        &.noPadding {
          padding-bottom: 0;
        }
        .label {
          color: #0008;
          @media (prefers-color-scheme: dark) {
            color: #fff8;
          }
        }
        p {
          margin: 0;
          line-height: 1em;
        }
      }
      .label {
        margin-top: 0;
      }
      span:before {
        content: "\f111";
        font-family: "FontAwesome";
      }
    }
  }

  .h-input {
    &:checked + .s-label {
      span:before {
        content: "\f192";
      }
    }
  }
}

#selDate {
  width: 100%;
  padding: 1em;
  color: #0003;
  border: 1px solid #0003;
  @media (prefers-color-scheme: dark) {
    color: #fff3;
    background-color: #333;
    border: none;
  }
  border-radius: $card-radius;
  display: block;
  margin: 0.5em 0;
  box-sizing: border-box;
}

.new-pren {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  .top {
    padding: 1em;
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 100px;
  }
  .btm {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #ffffffbe;
    -webkit-backdrop-filter: blur(20px) saturate(2);
    @media (prefers-color-scheme: dark) {
      background-color: #000000;
    }
    flex: 1;
    padding: 1em;
    padding-bottom: calc(1em + env(safe-area-inset-bottom));
    box-sizing: border-box;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.5s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
#confirm-icon{
  color: $color-green;
  font-size: 35px;
}
</style>
