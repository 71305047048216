<template>
    <main>
    <div class="column flex dangelo-login"  v-if="!$route.params.token">
        <a style="line-height: 48px;align-self:start;display:flex;align-items:center;" href="#" @click="$router.go(-1)"> <vue-feather type="chevron-left"/>Torna Indietro</a>
        <h1>Reset Passsword</h1>
        <p>Digita il tuo indirizzo email per richiedere il reset della password</p>
        <form @submit.prevent="send">
        <input type="email" class="input" placeholder="Indirizzo Email" v-model="email" style="margin: 1em 0;">
        <span v-if="done" style="margin: 1em 0;">
            <b>Operazione Completata! </b><br><br>Controlla la tua mail e segui le istruzioni.<br><br><br>
            <a href="/" class="btn">Torna alla Home</a>
        </span>
        <button v-else class="btn">Invia</button><br>
    </form>
    </div>
    <div class="column flex dangelo-login" v-else>
        <h1>Reset Passsword</h1>
        <p>Inserisci e conferma la password per recuperare l'accesso al tuo utente</p>
        <form @submit.prevent="sendResponse">
        <input type="password" v-model="modal.password" class="input" placeholder="Inserisci Password">
        <input type="password" v-model="modal.conferma" class="input" placeholder="Conferma Password"><br><br>
        <span v-if="error">
            <b>Errore: </b>{{error}}<br><br>
        </span>
        <span v-if="done">
            <b>Operazione Completata! </b><br /><br /><br />
            <a href="/" class="btn">Torna al Login</a>
        </span>
        <button v-else class="btn">Invia</button>
        </form>
    </div>
    </main>
</template>

<script>
import VueFeather from "vue-feather";
import ky from '../../network';
export default {
    data(){
        return {
            done: false,
            error: null,
            email: "",
            modal: {
                password: "",
                conferma: ""
            }
        }
    },
    components: {
        VueFeather
    },
    methods: {
        async send(){
            await ky.post("/api/Utente/Utente/ResetPassword?email="+this.email).json();
            this.done = true;
        },
        async sendResponse(){
            try {
                let r = await ky.post("/api/Utente/Utente/ResetPassword/" + this.$route.params.token,{json: this.modal}).json();
                if(r.status){
                    this.done = true;
                    this.error = null;
                } else {
                    this.error = r.error;
                }
            } catch(e){
                let r = await e.response.json();
                this.error = r.error;
            }
        }
    }
}
</script>
