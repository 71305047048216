<template>
  <div class="edit-pren" id="p-col" v-if="prenotazione">
    <div class="top flex column align-stretch">
      <br />

      <div class="nome">
        <b id="c-name">{{ prenotazione.utente.nome }} {{ prenotazione.utente.cognome }}</b>

      </div>
       <template v-if="tessereMancanti > 0 && ($root.parrucchiere || $root.segreteria) && prenotazione.orarioInizioEffettivo && !prenotazione.importoImpostato && !prenotazione.pagato">
          <button id="rimuovi" class="btn transparent" v-if="tessereMancanti > 0" style="margin: 0 auto" @click="utilizzaTessera(tessere.tessere[0].id)">Riscatta tessera ({{tessereMancanti}})</button>

        </template>
      <span class="label" style="margin: 0"
        >{{ ora }} • {{ prenotazione.servizi.map(m => m.nome).join(",")
        }}{{ prenotazione.checkIn ? "" : " • Check-in non effettuato."
        }}{{ importoImpostato && !prenotazione.pagato ? " • da pagare" : ""
        }}{{
          ($root.parrucchiere || $root.segreteria) && campagne.length > 0
            ? " • campagne disponibili"
            : ""
        }}</span
      >

      <div class="notes_container">
        <span class="label" style="margin-left:0">Note</span>
        <div class="note-form">
          <div v-if="prenotazione.note && prenotazione.note.length > 0">
            <span v-for="nota in prenotazione.note" :key="nota.id" class="nota">{{
              nota.note
            }}</span>
          </div>
          <br />
          <div class="flex justify-between">
            <input
              type="text"
              name=""
              id=""
              class="input"
              style="flex-grow: 1; height: 38px"
              placeholder="Scrivi una nota..."
              v-model="inputNota"
            />
            &nbsp;<button
              class="btn transparent"
              style="padding: 5px;width: 38px;"
              @click="aggiungiNota"
            >
              <vue-feather type="plus" />
            </button>
          </div>
        </div>
      </div>
      <div class="notes_container">
        <div>
          <span class="label" style="margin-left:0">Annotazione sul cliente</span>
        </div>
        <br>
        <div class="flex justify-between align-center">
          <textarea class="input" rows="4" style="flex-grow: 1;resize: vertical" v-model="inputAnnotazione"></textarea>
          <div class="flex column align-center" style="max-width: 38px">
            <button class="btn transparent" style="padding: 5px;width: 38px;height: 38px" v-on:click="modificaAnnotazione">
              <vue-feather type="edit"/>
            </button>
            <small>Salva</small>
          </div>
        </div>
      </div>
    </div>

    <div class="btm flex column align-stretch">
      <template v-if="$root.parrucchiere">
        <template v-if="importoImpostato">
          <span id="price"> €{{ prenotazione.importo }}</span>
        </template>
        <template v-else-if="conclusa">
          <TastierinoNumerico v-model="importo"></TastierinoNumerico>
          <!-- div  class="campagne" style="text-align:left" v-if="campagne.length > 0">
          <br />
          <span class="label">Campagne Marketing</span>
          <div v-for="c in campagne" :key="'campagna-' + c.id">{{c.nome}} <a  @click="utilizzaCM(c.idRelazione)">Abilita</a></div>
          <br>
          <br />
          </div -->
          <div class="conferma_imp">
            <button class="btn btn-lg green" style="margin: 0 auto" @click="salvaImporto">
              Conferma Importo
            </button>
          </div>
        </template>
        <template v-else-if="iniziata">
          <br />
          <span class="timer">{{ timer }}</span>
          <br />
          <button class="btn btn-lg green" style="margin: 0 auto" @click="concludi">Concludi</button>
        </template>
        <template v-else-if="prenotazione.checkIn">
          <br />
          <button class="btn btn-lg active" style="margin: 0 auto" @click="inizia">Inizia</button>
        </template>
      </template>
      <template v-else-if="!prenotazione.pagato && $root.segreteria">
        <div class="up-user" v-if="false">
          <!-- v-if="!prenotazione.utente.telefono || !prenotazione.utente.indirizzoEmail || !prenotazione.utente.dataNascita" -->
          <p>
            Questo utente non ha inserito alcune informazioni<br />Inseriscile per aggiornare
            l'utente
          </p>
          <br />
          <input
            class="input"
            type="text"
            v-if="!prenotazione.utente.numeroTelefono"
            v-model="formUtente.numeroTelefono"
            placeholder="numero di telefono"
            required
          />
          <br />
          <input
            class="input"
            type="email"
            v-if="!prenotazione.utente.indirizzoEmail"
            v-model="formUtente.indirizzoEmail"
            placeholder="email"
            required
          />
          <br />
          <a @click="updateUser()">Salva</a>
        </div>
        <template v-if="!prenotazione.checkIn">
          <br />
          <button class="btn btn-lg active" style="margin: 0 auto" @click="checkIn">Check In</button>
        </template>
        <template v-else-if="!prenotazione.orarioInizioEffettivo">
          <button class="btn btn-lg active" style="margin: 0 auto" @click="annullaCheckIn">
            Annulla Check In
          </button>
          <br />
        </template>
        <template v-else-if="importoImpostato">
          <b v-if="importoImpostato">€ {{ prenotazione.importo }}</b>
          <br />
          <b v-if="prenotazione.pagato">Pagato</b>
          <button class="btn btn-lg active" style="margin: 0 auto" v-else @click="paga">Paga</button>
        </template>
      </template>



      <a
        id="rimuovi"
        class="btn transparent"
        @click="mostraFormRimozione = true"
        v-if="!importoImpostato && !mostraFormRimozione && $root.segreteria"
      >
        rimuovi prenotazione
      </a>
      <div v-if="mostraFormRimozione" class="rimozione-form">
        <input type="text" class="input" placeholder="Motivazione" v-model="noteEliminazione" />
        <a href="#" @click="mostraFormRimozione = false">Annulla</a>
        <a class="btn transparent" id="rimuovi" @click="rimuovi">Conferma</a>
      </div>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import { it } from "date-fns/locale";
import ky from "../../network";
import utils from "../../utils";
import TastierinoNumerico from "./TastierinoNumerico.vue";
import VueFeather from "vue-feather";
export default {
  props: ["prenotazione", "orario", "data", "sidebar"],

  methods: {
    async inizia() {
      const r = await ky
        .post("/api/Staff/Prenotazione/Inizia", {
          searchParams: { id: this.prenotazione.id }
        })
        .json();
      this.$root.$emit("update");
      if (this.sidebar)
        this.$root.$emit("sidebar", {
          componentData: {
            prenotazione: r.prenotazione,
            data: this.data,
            ora: this.orario,
            sidebar: this.sidebar
          }
        });
    },
    async concludi() {
      this.$root.$emit("popup", {
        message: "Sei sicuro di voler concludere?",
        actions: [
          {
            name: "Concludi",
            class: "active",
            func: async () => {
              const r = await ky
                .post("/api/Staff/Prenotazione/Concludi", {
                  searchParams: { id: this.prenotazione.id }
                })
                .json();
              this.$root.$emit("update");
              if (this.sidebar)
                this.$root.$emit("sidebar", {
                  componentData: {
                    prenotazione: r.prenotazione,
                    data: this.data,
                    ora: this.orario,
                    sidebar: this.sidebar
                  }
                });
            }
          }
        ]
      });
    },
    async updateUser() {
      try {
        if (this.formUtente.dataNascita)
          this.formUtente.dataNascita = utils.UTC(this.formUtente.dataNascita);
        else this.formUtente.dataNascita = null;
        await ky.post("/api/Staff/Utente/Modifica", { json: this.formUtente });
        this.$root.$emit("update");
        this.$root.$emit("hideSidebar");
      } catch (e) {
        this.loading = false;
        let err = await e.response.json();
        // console.log(JSON.stringify(err));
        this.$root.$emit("snackbar", { type: "error", message: err.error });
      }
    },
    async salvaImporto() {
      const importo = parseFloat(this.importo);
      if (importo > 100 && confirm("L'importo è superiore a €100. Vuoi continuare?")) {
        await ky.post("/api/Staff/Prenotazione/ImpostaImporto", {
          searchParams: { id: this.prenotazione.id },
          json: { importo, note: this.note }
        });
        this.$root.$emit("update");
        if (this.sidebar) this.$root.$emit("hideSidebar");
      } else {
        await ky.post("/api/Staff/Prenotazione/ImpostaImporto", {
          searchParams: { id: this.prenotazione.id },
          json: { importo, note: this.note }
        });
        this.$root.$emit("update");
        if (this.sidebar) this.$root.$emit("hideSidebar");
      }
    },
    async rimuovi() {
      await ky.post("/api/Staff/Prenotazione/Elimina", {
        searchParams: { id: this.prenotazione.id, note: this.noteEliminazione }
      });
      this.$root.$emit("update");
      if (this.sidebar) this.$root.$emit("hideSidebar");
    },
    async paga() {
      await ky.post("/api/Staff/Prenotazione/Paga", {
        searchParams: { id: this.prenotazione.id },
        json: { note: this.note }
      });
      this.$root.$emit("update");
      if (this.sidebar) this.$root.$emit("hideSidebar");
    },
    async checkIn() {
      await ky.post("/api/Staff/Prenotazione/CheckIn", {
        searchParams: { id: this.prenotazione.id }
      });
      this.$root.$emit("update");
      if (this.sidebar) this.$root.$emit("hideSidebar");
    },
    async annullaCheckIn() {
      await ky.post("/api/Staff/Prenotazione/AnnullaCheckIn", {
        searchParams: { id: this.prenotazione.id }
      });
      this.$root.$emit("update");
      if (this.sidebar) this.$root.$emit("hideSidebar");
    },
    async utilizzaCM(id) {
      await ky.post("/api/Staff/Utente/CampagneMarketing", {
        searchParams: { id }
      });
      this.campagne = (
        await ky
          .get("/api/Staff/Utente/CampagneMarketing", {
            searchParams: { idUtente: this.prenotazione.utente.id }
          })
          .json()
      ).campagne;
    },
    async utilizzaTessera(id) {
      await ky.post("/api/Staff/Utente/Tessere", {
        searchParams: { id, idPrenotazione: this.prenotazione.id }
      });
      this.tessere = 
        await ky
          .get("/api/Staff/Utente/Tessere", {
            searchParams: { idUtente: this.prenotazione.utente.id }
          })
          .json()
    },
    async refresh() {
      if (this.sidebar) {
        let r = await ky
          .get("/api/Staff/Prenotazione", {
            searchParams: { id: this.prenotazione.id }
          })
          .json();
        this.$root.$emit("sidebar", {
          componentData: {
            prenotazione: r.prenotazione,
            data: this.data,
            ora: this.orario,
            sidebar: this.sidebar
          }
        });
      }
    },
    aggiornaTimer() {
      if (this.prenotazione.orarioInizioEffettivo) {
        const diff = this.prenotazione.timer;
        const ore = Math.floor(diff / 3600);
        const minuti = Math.floor((diff - ore * 3600) / 60);
        const secondi = Math.floor(diff - ore * 3600 - minuti * 60);
        if (ore === 0) {
          this.timer = `${minuti.toString().padStart(2, "0")}:${secondi
            .toString()
            .padStart(2, "0")}`;
        } else {
          this.timer = `${ore.toString().padStart(2, "0")}:${minuti
            .toString()
            .padStart(2, "0")}:${secondi.toString().padStart(2, "0")}`;
        }
        this.prenotazione.timer++;
      }
    },
    async aggiungiNota() {
      await ky.post("/api/Staff/Prenotazione/Nota", {
        searchParams: { id: this.prenotazione.id },
        json: { note: this.inputNota }
      });
      this.inputNota = "";
      this.$root.$emit("update");
    },
    async modificaAnnotazione(){
      await ky.post("/api/Staff/Utente/ModificaAnnotazione?idUtente="+this.prenotazione.utente.id+"&annotazione="+this.inputAnnotazione)
      this.$root.$emit("update");
    }
  },
  components: {
    TastierinoNumerico,
    VueFeather
  },
  data() {
    return {
      importo: "",
      timer: "",
      interval: null,
      refreshInterval: null,
      campagne: [],
      tessere: [],
      note: "",
      formUtente: JSON.parse(JSON.stringify(this.prenotazione.utente)),
      mostraFormRimozione: false,
      noteEliminazione: "",
      inputNota: "",
      inputAnnotazione: ""
    };
  },
   computed: {
    iniziata() {
      return (
        this.prenotazione.orarioInizioEffettivo !== null &&
        this.prenotazione.orarioInizioEffettivo !== undefined
      );
    },
    conclusa() {
      return (
        this.prenotazione.orarioFineEffettivo !== null &&
        this.prenotazione.orarioFineEffettivo !== undefined
      );
    },
    tessereMancanti() {
      if(!this.tessere || !this.tessere.tessere || this.tessere.tessere.length === 0) return 0
      return this.tessere.tessere.filter(t => !t.utilizzata).length
    },
    importoImpostato() {
      return this.prenotazione.importo !== null && this.prenotazione.importo !== undefined;
    },
    giorno() {
      return format(new Date(this.prenotazione.dataPrenotazione), "dd MMMM yyyy", { locale: it });
    },
    ora() {
      return utils.parseOrario(this.prenotazione.orarioInizioPrevisto);
    }
  },
  async created() {
    this.interval = setInterval(this.aggiornaTimer, 1000);
    this.refreshInterval = setInterval(this.refresh, 3000);
    this.inputAnnotazione = this.prenotazione.utente.annotazione != null ? this.prenotazione.utente.annotazione : ""
    if (!this.importoImpostato && this.conclusa) {
      this.campagne = (
        await ky
          .get("/api/Staff/Utente/CampagneMarketing", {
            searchParams: { idUtente: this.prenotazione.utente.id }
          })
          .json()
      ).campagne;


    }

       this.tessere = await ky
          .get("/api/Staff/Utente/Tessere", {
            searchParams: { idUtente: this.prenotazione.utente.id }
          }).json()

      console.log(this.tessere)
    if(this.prenotazione.note !== null){
      let note = this.prenotazione.note.filter(n => n.tipo === (this.$root.parrucchiere ? "Segreteria" : "Parrucchiere") && !n.notificata);
      for(let n of note){
        await ky.post("/api/Staff/Prenotazione/LeggiNota", {searchParams: { id: n.id }}).json()
        n.notificata = true;
      }
    }
  },
  watch: {
    'prenotazione.utente.id': async function(){
      this.inputAnnotazione = this.prenotazione.utente.annotazione != null ? this.prenotazione.utente.annotazione : ""
      this.tessere = await ky.get("/api/Staff/Utente/Tessere", {
            searchParams: { idUtente: this.prenotazione.utente.id }
          }).json()
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.refreshInterval);
  }
};
</script>

<style lang="scss">
@import "@/style/variables.scss";

span.nota {
  display: inline-block;
  margin: 3px;
  background-color: rgba(0, 0, 0, 0.1);
  @media (prefers-color-scheme: dark) {
    background-color: rgba(255, 255, 255, 0.1);
  }
  padding: 5px;
  border-radius: 7px;
  font-size: 12px;
}

.notes_container {
  margin: 0.5em auto;
  width: 100%;
  text-align: left;
}


.edit-pren {
  flex-direction: column;
}

#c-name {
  font-size: 2em;
}

#p-col {
  height: 100%;
  padding: 0 2em;
  display: grid;
  grid-template-rows: 2fr 1fr;
  justify-content: stretch;
  align-items: center;
  box-sizing: border-box;
  text-align: center;
}

#rimuovi {
  font-weight: 600;
  color: $color-red;
}
.up-user {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  box-sizing: border-box;
  p {
    color: $text-muted;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .btn {
    line-height: 32px;
  }
}

.rimozione-form {
  display: flex;
  flex-wrap: wrap;
  padding: 16px 0;
  align-items: center;
  input {
    width: 100%;
    padding: 16px 8px;
  }
  a {
    display: block;
    width: 50%;
    box-sizing: border-box;
    text-align: center;
  }
}

</style>
