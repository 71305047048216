<template>
    <div class="weekSel flex align-center justify-between">
      <div class="arrow" id="selLeft" @click="decrement"><i class="fa fa-chevron-left"></i></div>
      <div id="selText">{{text}}</div>
      <div class="arrow" id="selRight" @click="increment"><i class="fa fa-chevron-right"></i></div>
    </div>
</template>

<script>
import { format, addDays, subDays,
} from 'date-fns';
import { it } from 'date-fns/locale';

export default {
  data() {
    return {
      today: null,
    };
  },
  computed: {
    text() {
      return `${format(this.$root.dateStart, (this.$root.dateStart.getMonth() === this.$root.dateEnd.getMonth() ? 'dd' : 'dd MMM'), { locale: it })} - ${format(this.$root.dateEnd, 'dd MMM', { locale: it })}`;
    },
  },
  methods: {
    decrement() {
      this.$root.dateStart = subDays(this.$root.dateStart, 7);
      this.$root.dateEnd = subDays(this.$root.dateEnd, 7);
    },
    increment() {
      this.$root.dateStart = addDays(this.$root.dateStart, 7);
      this.$root.dateEnd = addDays(this.$root.dateEnd, 7);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/variables.scss';
  .arrow{
    user-select: none;
    cursor: pointer;
    text-align: center;
    color: #00000059;
    &:hover {
      color: $color-accent;
      @media (prefers-color-scheme: dark) {
        color: white;
      }
    }
    &:active{
      background-color: #EEE;
    }
    @media (prefers-color-scheme: dark) {
      color: #ddd;
      &:active{
        background-color: #333;
      }
    }
  }

  .weekSel {
    border-radius: $card-radius;
    border: 1px solid #0003;
    height: 34px;
    line-height: 34px;
    overflow: hidden;
    width: 204px;
    #selLeft {
      width: 34px;
      border-right: 1px solid #0003;
    }
    #selText {
      padding: 0 .5em;
      font-size: 12px;
      color: #333;
      font-weight: 600;
      text-transform: uppercase;
    }
    #selRight {
      width: 34px;
      border-left: 1px solid #0003;
    }
    @media (prefers-color-scheme: dark) {
      border-color: #fff3;
      #selText {
        color: #ddd;
      }
      #selLeft, #selRight {
        border-color: #fff3
      }
    }
  }
</style>
