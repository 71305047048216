<template>
    <div>
      <template v-if="extended">
      <div class="flex align-end d-day-cont" >
        <div class="d-arrow" @click="decrementWeek"><i class="fa fa-chevron-left"></i></div>
        <div class="d-day" v-for="(d,i) in week" :key="i"  @click="selectDay(d)" :class="{active: areSame(d,$root.date)}">
          <small class="label">{{weekText(d).dayOfWeek}}</small>
          <br>
          <button class="btn round" :class="{active: areSame(d,$root.date)}">
            {{parseInt(weekText(d).dayOfMonth)}}
          </button>
        </div>
        <div class="d-arrow" @click="incrementWeek"><i class="fa fa-chevron-right"></i></div>
      </div>
      <div class="m-show">
      <p style="margin-top:0;text-transform: capitalize" align="center" @click="selectDay(today)">{{text(true)}}</p>
      </div>
      </template>
      <!-- div class="flex align-center" v-else>
        <div class="arrow" @click="decrement"><i class="fa fa-chevron-left"></i></div>
        <div>{{text()}}</div>
        <div class="arrow" @click="increment"><i class="fa fa-chevron-right"></i></div>
      </div -->
      <DatePicker mode="single"
            :value="$root.date"
            class="datePick"
            @input="v => selectDay(v)" v-else />
    </div>
</template>

<script>
import { format, addDays, subDays, eachDayOfInterval, isSameDay, isBefore, startOfWeek} from 'date-fns';
import { it } from 'date-fns/locale';
import utils from '../../utils';
import DatePicker from "v-calendar/lib/components/date-picker.umd";

export default {
  props: [
    'extended'
  ],
  components: {
    DatePicker
  },
  data() {
    return {
      today: null,
      startWeek: null
    };
  },
  created() {
    // this.$root.date = this.today;
    let stWk = startOfWeek(new Date(), {weekStartsOn: 1});
    this.startWeek = utils.UTC(stWk);
    this.today = utils.UTC();
    this.$root.date = this.today
  },
  computed: {
    week () {
      return eachDayOfInterval({start:this.startWeek, end: addDays(this.startWeek, 6)})
    }
  },
  watch: {
    "$root.date": function() {
      if (!this.extended) return
      if (!(this.week.find(d => isSameDay(d, this.$root.date)))) {
        if (isBefore(this.$root.date, this.week[0])) this.decrementWeek()
        else this.incrementWeek()
      }
    }
  },
  methods: {
    decrement() {
      this.$root.date = subDays(this.$root.date, 1);
    },
    text(full) {
      // console.log(this.$root.date)
      return format(this.$root.date,(full ? 'EEEE dd MMMM yyyy': 'dd MMM '), { locale: it });
    },
    increment() {
      this.$root.date = addDays(this.$root.date, 1);
    },
    areSame(d,i) {
      // console.log(d,i)
      return isSameDay(utils.UTC(d), utils.UTC(i))
    },
    selectDay(d) {
      this.$root.date = utils.UTC(d);
    },
    decrementWeek() {
      this.startWeek = subDays(this.startWeek, 7);
    },
    incrementWeek() {
      this.startWeek = addDays(this.startWeek, 7);
    },
    weekText(d) {
      return {
        dayOfWeek: format(d,'EEEEE', { locale: it }),
        dayOfMonth: format(d,'dd', { locale: it })
      }
    }
  },
};
</script>

<style lang="scss" >
@import '@/style/variables.scss';

.d-arrow {
  margin: 0 1em;
  margin-bottom: 1.5em;
}

.datePick {
  .vc-text-base {
    text-align: center;
    background-color: transparent;
    border-radius: $card-radius!important;
    box-sizing: border-box;
    @media (prefers-color-scheme: dark) {
      border-color: #fff3;
      color: #DDD
    }
  }
}



  .d-day-cont {
    width: 100%;
    justify-content: space-around;
    .d-day {
      text-align: center;
      margin: 1em 0;
      cursor: pointer;
      transition: none;
      .btn {
        display: block;
        width: 28px;
        height: 28px;
        padding: 1em;
        padding: 0;
        line-height: 35px;
        background-color: transparent;
        border: none;
        &.active {
          box-shadow: $card-shadow-front
        }
      }

    }
    @media screen and (min-width: 1041px) {
      margin-left: 3em;
      justify-content: center;
      .d-day {
        margin: 0 1em;
        padding: 4px 10px;
        // border-radius: $card-radius;
        .btn {
          transition: none;
          box-shadow: none!important;
          background: none;
          border: none;
        }
        &.active {
          box-shadow: $card-shadow-front;
          background: $accent-gradient;
          .label {
            color: white
          }
          @media (prefers-color-scheme: dark) {
            background-color: #333
          }
        }
      }
    }
  }




</style>
