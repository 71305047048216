<template>
  <transition name="snackshow">
    <div
      class="snackbar"
      @click="snackbarData.action ? snackbarData.action() : fake()"
      :class="snackbarData.type"
      v-if="show"
    >
      <i id="snack-icn" :class="snackIcon"></i>
      <span id="snack-msg">{{snackbarData.message}}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data(){
    return {
      snackbarData: null,
    }
  },
  computed: {
    snackIcon () {
      if(!this.snackbarData)return '';
      switch (this.snackbarData.type) {
        case 'done':
          return 'fa fa-check'
        case 'error':
          return 'fa fa-times'
        default:
          return '';
      }
    },
    show(){
      return !!this.snackbarData
    }
  },
  methods: {
    fake(){
      this.snackbarData = null;
    }
  },
  created(){
    this.$root.$on("snackbar",(e)=>{
      this.snackbarData = e
      setTimeout(()=>{
        this.snackbarData = false;
      },5000);
    });
  }

}
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";

@mixin snackMode($color) {
  background-color: $color;
  // box-shadow: 0px 3px 0 darken($color, 5%);
  &:hover {
    background-color: darken($color, 5%);
    // box-shadow: 0px 3px 0 darken($color, 10%);
  }
}

.snackbar {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  align-items: center;
  width: calc(100% - 2em);
  left: 1em;
  box-sizing: border-box;
  min-height: 64px;
  padding: 0 1em;
  color: white;
  font-weight: 600;
  position: fixed;
  bottom: calc(.5em + env(safe-area-inset-bottom));
  border-radius: $card-radius;
  transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  z-index: 1000;
  @include snackMode($color-green);

  &.error {
    @include snackMode($color-red);
  }

  #snack-icn {
    margin-right: 1em;
    font-size: 24px;
    opacity: 0;
    animation: 0.5s icn-show cubic-bezier(0.19, 1, 0.22, 1) forwards;
    animation-delay: 0.6s;
  }
  #snack-msg {
    opacity: 0;
    animation: 0.7s show-right forwards cubic-bezier(0.19, 1, 0.22, 1);
    animation-delay: 0.8s;
  }
}

@keyframes icn-show {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes show-btm {
  from {
    opacity: 0;
    transform: translatey(100%);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}

@keyframes show-right {
  from {
    opacity: 0;
    transform: translatex(-1em);
  }
  to {
    opacity: 1;
    transform: translatex(0);
  }
}

.snackshow-enter-active,
.snackshow-leave-active {
  transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.snackshow-enter, .snackshow-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(100%);
  opacity: 0;
}
</style>
