<template>
    <div class="marketingCard">
        <div class="flex justify-between align-center">
            <div>
                <h2>{{c.nome}}</h2>
                <p>{{c.descrizione}}</p>
            </div>
            <i class="fas fa-gift"></i>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'c'
    ]
}
</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';

.marketingCard {
    @include card;
    border: none;

    box-shadow: none;
    color: black;
    background: linear-gradient(-45deg, #5dffda, #4bead4);
    margin: 1em 0;
    h2,p {
        margin: 0;
        color: black
    }
    i {
        font-size: 1.5em;
        color: black
    }
}
</style>