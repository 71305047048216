var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"go-container"},[_vm._l((_vm.orari),function(o){return [_c('div',{key:o + 'qt',staticClass:"go-row",class:{
        quarter: _vm.formatHour(o).indexOf(':00') === -1 && _vm.formatHour(o).indexOf(':30') === -1,
        ferie: _vm.ferie ? _vm.ferie.find(function (f) { return o > f.oraInizio && o < f.oraFine; }) : false
      },style:({ height: _vm.rowHeight + 'px' })})]}),_vm._l((_vm.prenotazioni),function(p){return _c('div',{key:p.id,staticClass:"go-item",style:({
      top: _vm.calcPosition(_vm.fnPosition(p)) + 'px',
      height: _vm.calcHeight(p.orarioFinePrevisto - p.orarioInizioPrevisto) + 'px'
    })},[_vm._t("prenotazione",null,{"prenotazione":p})],2)}),(_vm.ferie && _vm.ferie.length > 0)?_vm._l((_vm.ferie),function(f){return _c('div',{key:f.id,staticClass:"go-item",style:({
        top: f.oraInizio == null ? '0' : _vm.calcPosition(f.oraInizio) + 'px',
        height: f.oraFine == null ? '100%' : _vm.calcHeight(f.oraFine - f.oraInizio) + 'px'
      })},[_c('div',{staticClass:"cardFerie"})])}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }