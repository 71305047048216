<template>
  <div class="settimana flex row justify-stretch" v-if="orari.length > 0">
    <div class="hours">
      <span
        v-for="h in orari"
        :key="h"
        class="hour d-block"
        :class="{quarter: formatHour(h).indexOf(':00') === -1 && formatHour(h).indexOf(':30') === -1}"
        >{{ formatHour(h) }}</span
      >
    </div>
    <div style="flex: 1; position: relative">
      <div class="dateHead flex justify-stretch">
        <template class="date" v-for="(data, i) in settimana">
          <div class="date" :key="i">
            <span class="label date-header">{{ format(data) }}</span>
          </div>
        </template>
      </div>
      <div class="flex justify-stretch">
        <div id="currentHour" ref="hour" :style="{ top: topHour + 'px' }"></div>
        <template class="date" v-for="(data, i) in settimana">
          <div class="date" :key="i">
            <GrigliaOrari
              :fnPosition="(p) => p.orarioInizioPrevisto"
              :rowHeight="64"
              :ferie="orariSettimana[i].ferie "
              :orari="orari || {}"
              :prenotazioni="
                orariSettimana[i] ? orariSettimana[i].prenotazioni : []
              "
              :data="settimana[i]"
            >
              <template v-slot:prenotazione="p">
                <card-prenotazione-compact
                  :prenotazione="p.prenotazione"
                ></card-prenotazione-compact>
              </template>
            </GrigliaOrari>
          </div>
        </template>
      </div>
    </div>
    <div
      class="fab"
      :class="{ 'sidebar-shown': $root.sidebarShown }"
      @click="mostraForm"
    >
      <vue-feather type="plus"/>
    </div>
  </div>
  <loading v-else text="Cerco le prenotazioni..." />
</template>

<script>
/* eslint-disable vue/no-unused-components */
import { eachDayOfInterval, format, isSameDay } from "date-fns";
import { it } from "date-fns/locale";
import utils from "../../utils";
import ky from "../../network";
import VueFeather from 'vue-feather';

import CardPrenotazioneCompact from "../components/CardPrenotazioneCompact.vue";
import NuovaPrenotazione from "../../common/components/NuovaPrenotazione.vue";
import GrigliaOrari from "../../common/components/GrigliaOrari.vue";
import Loading from "../../common/components/Loading.vue";

export default {
  data() {
    return {
      orariSettimana: [],
      settimanaDaMostrare: [],
      orari: [],
      created: false,
      interval: null,
    };
  },
  computed: {
    settimana() {
      return eachDayOfInterval({
        start: this.$root.dateStart,
        end: this.$root.dateEnd,
      }).map((d) => utils.UTC(d));
    },
    topHour () {
        let p = new Date()
        let parsedDate = p.getHours() * 3600 + p.getMinutes() * 60
        for (let i = 0; i < this.orari.length; i ++) {
          if (this.orari[i] >= parsedDate) {
            let mins = (this.orari[i] - parsedDate)/60

            let x = (mins*32)/15

            return (((i) * 64) + 26 - x)
          }
        }
        return 0
    }
    },
  watch: {
    "$root.idParrucchiere": function () {
      if (!this.created) return;
      this.update();
    },
    "$root.dateStart": function () {
      if (!this.created) return;
      this.update();
    },
    "$root.dateEnd": function () {
      if (!this.created) return;
      this.update();
    },
  },
  methods: {
    check(d, data) {
      let same = isSameDay(new Date(d), data);
      return same;
    },
    mostraForm() {
      this.$root.$emit("sidebar", {
        component: NuovaPrenotazione,
        title: "Prenota",
      });
    },
    format(date) {
      return format(date, "EEE dd MMM", { locale: it });
    },
    formatHour(h) {
      return utils.parseOrario(h);
    },
    async update() {
      let np = 0;
      let counter = 0;
      this.settimana.forEach(async (data) => {
        let searchParams = { timestamp: data.getTime() / 1000 };
        if (this.$root.segreteria && this.$root.idParrucchiere !== null) searchParams.idParrucchiere = this.$root.idParrucchiere;
        const res = await ky.get("/api/Staff/Orario", { searchParams }).json();
        np += res.prenotazioni.length;
        this.$set(this.orariSettimana, this.settimana.indexOf(data), res);
        counter += 1;
        if (counter === this.settimana.length) {
          let minOrario = Number.MAX_SAFE_INTEGER;
          let maxOrario = 0;
          this.orariSettimana.forEach((x) => {
            if (x.orari) {
              minOrario = Math.min(minOrario, ...x.orari);
              maxOrario = Math.max(maxOrario, ...x.orari);
            }
          });
          let orari = [];
          for (let i = minOrario; i <= maxOrario; i += 900) orari.push(i);
          orari = orari.sort((a, b) => a - b);
          this.orari = orari;
          this.$root.numeroPrenotazioni = np;
        }
      });
    },
  },
  async created() {
    await this.update();
    setTimeout(() => {
      document
        .querySelector(".page")
        .scrollTo(0, this.topHour - window.innerHeight / 3);
    }, 1000);
    this.created = true;
    this.$root.$on("update", this.update);
    this.interval = setInterval(this.update, 15000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    CardPrenotazioneCompact,
    GrigliaOrari,
    Loading,
    VueFeather
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";
.settimana {
  padding: 2em;
  box-sizing: border-box;
}
.date {
  flex: 1;
  color: #707070;
  position: relative;
}

.notAvailableBox {
  width: calc(100% - 1em);
  height: calc(100% - 5em);
  background-color: #e0e0e0;
  position: absolute;
  left: 1.5em;
  z-index: 1;
  border-radius: 12px;
  box-sizing: border-box;
}

.hours {
  padding-top: 52px;
  padding-right: 2em;
  color: #555555;
}

.hour {
  height: 64px;
  &.quarter {
    opacity: 0.5;
  }
}
</style>
