<template>
  <div class="tabbar">
    <router-link class="tab_i" :to="'/home'" :class="{ 'active': $route.path == '/home' }">
      <vue-feather type="home" />
    </router-link>
    <!-- div class="user">{{$root.parrucchiere.nome}}</div -->
    <div class="fab tab_m" :class="{ 'sidebar-shown': $root.sidebarShown }" @click="mostraForm">
      <vue-feather type="plus" />
    </div>
    <div class="tab_i" @click="logout" v-if="$root.parrucchiere">
      <vue-feather type="log-out" />
    </div>
    <router-link :to="'/profilo'" class="tab_i" :class="{ 'active': $route.path == '/profilo' }" v-if="$root.utente">
      <vue-feather type="user" />
    </router-link>
  </div>
</template>

<script>
import ky from '../../network';
import NuovaPrenotazione from './NuovaPrenotazione.vue';
import VueFeather from 'vue-feather';

export default {

  components: {
    VueFeather
  },

  methods: {
    async logout() {
      this.$root.$emit('popup', {
        message: "Vuoi uscire?", hideViewOnAction: true, actions: [
          {
            name: "Esci",
            class: "active",
            func: async () => {
              await ky.post("/api/Login/Logout");
              document.location.href = '/';
            }
          }
        ]
      })

    },
    mostraForm() {
      this.$root.$emit('sidebar', { component: NuovaPrenotazione, title: "Prenota" });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/variables.scss";

.tabbar {

  position: relative;
  bottom: 0;
  background-color: rgba(255, 255, 255, .95);
  left: 0;
  width: 100%;


  min-height: 64px;
  border-bottom: $card-border;
  z-index: 9;
  box-shadow: $card-shadow-front;
  padding: 0 2em;
  display: flex;
  align-items: center;

  /* t'accid
        width: calc(100% - 4em); */
  @media screen and (max-width: 768px) {
    padding: 0;
    padding-top: 0;
    padding-bottom: env(safe-area-inset-bottom);
  }

  width: 100%;
  box-sizing: border-box;

  @media (prefers-color-scheme: dark) {
    background-color: rgba(0, 0, 0, .9);
    box-shadow: none;
    border: none;
  }

  -webkit-backdrop-filter: blur(20px) saturate(2);
}

.tab_m {
  margin: 0 auto;
  transform: translateY(-1em);
}

.tab_i {
  margin: 0 auto;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  text-align: center;
  height: 100%;
  width: 64px;
  font-size: 12px;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: 0.2s cubic-bezier(0.19, 1, 0.22, 1);

  i {
    font-size: 16px;
  }

  color: #707070;

  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
  }

  opacity: 0.5;

  &:active {
    background-color: rgba(0, 0, 0, .1);
  }

  &.active {
    // font-weight: 600;
    color: $color-accent;

    @media (prefers-color-scheme: dark) {
      color: white;
    }

    opacity: 1;
  }


}

.user {
  font-weight: bold;
  color: $color-accent;

  @media (prefers-color-scheme: dark) {
    color: white;
  }

  text-transform: capitalize;
}</style>
