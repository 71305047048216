<template>
    <label class="switch" :class="{checked: checked}">
        <input type="checkbox"  :checked="value" @input="input" class="hidden">
        <div class="bg">
            <div id="circ"></div>
        </div>
    </label>
</template>

<script>
export default {

    props: [
        'value'
    ],

    data () {
        return {
            checked: this.value
        }
    },

    methods: {
        input (e) {
            this.checked = e.target.checked;
            this.$emit('input', e.target.checked)
        }
    }

}
</script>

<style lang="scss" scoped>

@import '@/style/variables.scss';
.switch {
    position: relative;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    .hidden {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
    .bg {
        width: 54px;
        transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
        height: 34px;
        border-radius: 17px;
        background-color: #ddd;
        @media (prefers-color-scheme: dark) {
            background-color: #222;
        }
    }
    
    
    #circ {
        width: 30px;
        height: 30px;
        position: relative;
        top: 2px;
        left: 2px;
        border-radius: 50%;
        background: white;
        filter: drop-shadow(0px 2px 5px rgba(0,0,0,.1));
        transition: .5s cubic-bezier(0.19, 1, 0.22, 1);
    }
    &.checked {
        .bg {
            background-color: $color-green;
        }
        #circ {
            transform: translateX(20px);
        }
    }
}


</style>