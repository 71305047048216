<template>
  <div class="loading m-hide" :class="{full: fullscreen}">
    <div>
      <h1>
        <img src="@/common/img/puff.svg" />
      </h1>
      <p>{{text}}</p>
    </div>
  </div>
</template>
<script>

export default {

  props: [
    'fullscreen',
    'text'
  ]

}

</script>
<style lang="scss" scoped>
@import '@/style/variables.scss';

.loading {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $text-muted;
  text-align: center;
  &.full {
    background-color: #FFFFFF;
    @media (prefers-color-scheme: dark) {
      background-color: black;
    }
    position: fixed;
    top:0;
    left:0;
    z-index: 9
  }
}

</style>
