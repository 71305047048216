import ky from 'ky';
let promise = null;
async function refreshSessione() {
  if(promise) await promise;
  else{
    promise = (async() => {
      try {
        const res = await ky.post('/api/Login/RinnovaSessione').json();
        if (res.status) {
          promise = null;
        } else throw Error();
      } catch (e) {
        if(document.location.hash.indexOf("#/resetPassword/") === -1){
          document.location.replace('/#/login');
        }
      }
    })();
    await promise;
  }
}

export default ky.create(
  {
    hooks: {
      beforeRequest: [
        async () => {
          var cookies = document.cookie.split(";");
          if(!cookies.some(x => x.indexOf("dangelo_session_token=") !== -1)){
            await refreshSessione();
          }
        },
      ],
    },
  },
);

export {refreshSessione}