<template>
    <div class="flex column align-center justify-center">
        <strong>Aggiungi appuntamento su:</strong>
        <a :href="urlGoogle" target="_blank">
            <i class="fa fa-google"></i> - Google calendar
        </a>
        <a :href="urlYahoo" target="_blank">
            <i class="fa fa-yahoo"></i> - Yahoo calendar
        </a>
    </div>
</template>
<script>
export default {
    props: ["nomeServizio", "data", "orario", "orarioFine", "nomeParrucchiere"],
    computed: {
        titolo () {
            return this.formatString(`D'Angelo Acconciatori: ${this.nomeServizio}.`);
        },
        dettagli () {
            return this.formatString(`Ciao, ti ricordiamo che hai fissato un appuntamento (${this.nomeServizio}) con ${this.nomeParrucchiere}.\nTi aspettiamo!`);
        },
        dataCompleta () {
            return new Date(this.data.setHours(this.orario.split(":")[0], this.orario.split(":")[1]));
        },
        dataCompletaFine () {
            return new Date(this.data.setHours(this.orarioFine.split(":")[0], this.orarioFine.split(":")[1]));
        },
        location () {
            return this.formatString('Via S. Gaetano, 135, 93017 San Cataldo CL');
        },

        urlGoogle () {
            return 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false&' +
            `text=${this.titolo}&location=${this.location}&details=${this.dettagli}&dates=${this.formatDateGoogle(this.dataCompleta)}/${this.formatDateGoogle(this.dataCompletaFine)}`;
        },
        urlYahoo () {
            return 'https://calendar.yahoo.com/?v=60&view=d&type=20&' +
            `title=${this.titolo}&st=${this.formatDateYahoo(this.dataCompleta)}&et=${this.formatDateYahoo(this.dataCompletaFine)}&desc=${this.dettagli}&in_loc=${this.location}`;
        }
    },
    methods: {
        formatString (string) {
            return encodeURIComponent(string).replace(/%20/g, '+');
        },
        formatDateGoogle (date) {
            return date.toISOString().replace(/-|:|\.\d+/g, '');
        },
        formatDateYahoo (date) {
            return (date.toISOString().substring(0, date.toISOString().indexOf("T")+1) + date.toLocaleTimeString() + "Z").replace(/-|:|\.\d+/g, '');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/style/style.scss";
div{
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: $text-muted;
    padding : 0 15px;
}
strong{
    margin-bottom: 12px;
}
a{
    margin-bottom: 8px;
}
</style>