export default {
  parseOrario(orarioIntero, obj = false) {
    const ore = Math.floor(orarioIntero / 3600);
    const minuti = Math.floor((orarioIntero - (ore * 3600)) / 60);
    if (obj) return {
      ore: ore.toString().padStart(2, '0'),
      minuti: minuti.toString().padStart(2, '0'),
      string: `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`
    }
    return `${ore.toString().padStart(2, '0')}:${minuti.toString().padStart(2, '0')}`;
  },
  UTC(date = new Date()) {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  }
};
