<template>
  <div>
    <span id="price">€{{valore || "0.00"}}</span>
    <input type="text" v-show="false" :value="valore">
    <div class="tastiera">
      <div class="pulsante" @click="add('1')">1</div>
      <div class="pulsante" @click="add('2')">2</div>
      <div class="pulsante" @click="add('3')">3</div>
      <div class="pulsante" @click="add('4')">4</div>
      <div class="pulsante" @click="add('5')">5</div>
      <div class="pulsante" @click="add('6')">6</div>
      <div class="pulsante" @click="add('7')">7</div>
      <div class="pulsante" @click="add('8')">8</div>
      <div class="pulsante" @click="add('9')">9</div>
      <div class="pulsante" @click="add('.')">.</div>
      <div class="pulsante" @click="add('0')">0</div>
      <div class="pulsante" @click="remove"><vue-feather type="delete"/></div>
    </div>
  </div>
</template>

<script>
import VueFeather from 'vue-feather';

export default {

  components: {
    VueFeather
  },

  props: {
    value: {
      default: ''
    },
  },
  data() {
    return {
      valore: '',
    };
  },
  watch: {
    value(v) {
      this.valore = v;
    },
  },
  methods: {
    emit() {
      this.$emit('input', this.valore ? parseFloat(this.valore.replace(',','.')) : 0);
    },
    add(c) {
      const decimali = this.valore.toString().split(',');
      if (decimali[1] && decimali[1].length === 2) return;
      this.valore += c;
      this.emit();
    },
    remove() {
      this.valore = this.valore.toString().substr(0, this.valore.toString().length - 1);
      this.emit();
    },
    aggiornaDaInput(v) {
      let str = v.target.value;
      str = str.replace('.', ',');
      const decimali = str.toString().split(',');
      if (decimali.length === 2) {
        str = `${decimali[0]},${decimali[1].substr(0, 2)}`;
      }
      this.valore = str;
      /* eslint-disable no-param-reassign */
      v.target.value = str;
      this.emit();
    },
  },
};
</script>

<style lang="scss" scoped>
#price {
  display: block;
  font-size: 2em;
  text-align: center;
  margin: .2em auto;
}
.tastiera {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  place-items: center;
}

.pulsante {
    cursor: pointer;
    padding: .2em;
    // margin: 0.25em;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-align: center;
    width: 90px;
    height: 32px;
    line-height: 32px;
    // transition: .2s;
    font-size: 1.5em;
    border-top: 1px solid rgba(0,0,0,.2);
    border-left: 1px solid rgba(0,0,0,.2);
  &:nth-of-type(-n+3) {
    border-top: none
  }

  &:nth-of-type(1), &:nth-of-type(4), &:nth-of-type(7), &:nth-of-type(10) {
    border-left: none
  }

  &:hover {
    background-color: rgba(0,0,0,.1);
  }
  &:active {
    background-color: rgba(0,0,0,.2);
  }
}

@media (prefers-color-scheme: dark){
  .pulsante {
    background-color: #111;
  }
}
</style>
