<template>
  <transition name="pop">
    <div class="flex column popup" v-if="show" :class="{hide: hide}">
      <transition name="fade">
      <div v-if="!hide" class="flex column content align-center">
        <h1 style="text-align: center">{{popData.message}}</h1>
        <div class="flex column actions justify-center align-center">
          <button
            class="btn btn-lg"
            v-bind:class="p.class"
            v-for="(p, i) in popData.actions"
            :style="p.style"
            :key="i"
            @click="run(p.func)"
          >{{p.name}}</button>
          <button class="btn transparent btn-lg" v-if="!popData.mandatory" @click="fake()">Annulla</button>
        </div>
      </div>
    </transition>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      popData: null,
      hide: false
    };
  },
  computed: {
    show() {
      return !!this.popData;
    }
  },
  methods: {
    fake() {
      this.popData = null;
    },
    run(func) {
      if (this.popData.hideViewOnAction) this.hide = true;
      else this.fake();
      func()
    }
  },
  created() {
    this.$root.$on("popup", e => {
      this.popData = e;
    });
  }
};
</script>

<style lang="scss" scoped>
@import "../../style/variables.scss";

.popup {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 99;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8);
  &.hide {
    background-color: #FFFFFF;
    @media (prefers-color-scheme: dark) {
      background-color: #000000;
    }
  }
  @media (prefers-color-scheme: dark) {
    background-color: rgba(0, 0, 0, 0.8);
    color: #EEEEEE;
  }
   -webkit-backdrop-filter: blur(20px) saturate(2);
  .content {
      animation: zoom .4s cubic-bezier(0.19, 1, 0.22, 1);
      .btn {
          margin-bottom: 1em;
      }
  }
}

@keyframes zoom {
    from {
        transform: scale(1.3);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

.pop-enter-active,
.pop-leave-active {
  transition: .2s;
}
.pop-enter, .pop-leave-to /* .fade-leave-active below version 2.1.8 */ {
   -webkit-backdrop-filter: blur(0) saturate(1);
  opacity: 0;
}
</style>
