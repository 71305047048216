<template>
    <main>
    <div class="dangelo-login reg">
      <form @submit.prevent="registrazione">
        <template v-if="step === 0">
        <h2 style="text-align:left">1. Informazioni</h2>
        <p>Dicci chi sei per farci riconoscere le tue prenotazioni!</p>
        <br>
        <input type="text" v-model="input.nome" placeholder="inserisci il tuo nome" required />
        <br>
        <input type="text" v-model="input.cognome" placeholder="inserisci il tuo cognome" required />
        <br>
        <date-picker
            v-model="input.dataNascita"
            mode="single"
            :input-props='{
                "class" : "signup-input",
                "placeholder": "inserisci la tua data di nascita (facoltativa)"
            }'
        />
        <br>
        <input type="text" v-model="input.numeroTelefono" placeholder="inserisci il tuo numero di telefono" required />
        <br>
        <button type="button" id="login-button" @click.prevent="step++" class="btn btn-lg" :disabled="!input.nome || !input.cognome || !input.numeroTelefono">Avanti</button>
        </template>
        <template v-else-if="step == 1">
        <h2 style="text-align:left"><button @click="step--" class="btn round transparent"><i class="fa fa-arrow-left"></i></button> 2. Privacy</h2>
        <p>Scegli delle credenziali per accedere al tuo profilo.</p>
        <br>
        <input type="email" v-model="input.indirizzoEmail" placeholder="inserisci la tua e-mail" required/>
        <br>
        <input type="password" v-model="input.password" placeholder="inserisci la tua password"  required/>
        <br>
        <input type="password" v-model="input.confermaPassword" placeholder="conferma la tua password"  required />
        <span v-if="error.length > 0" id="error">Si è verificato un errore: {{error}}</span>
        <br><br>
        <div class="flex justify-between align-center">
        <SwitchSelect type="checkbox" v-model="input.abilitaMarketing"/> Ricevi offerte e notifiche sui nostri servizi</div>
        <br><br>
        <small>Registrandoti, accetti la nostra <a href="/privacy-policy.html" target="_blank">Privacy Policy</a></small>
        <button id="login-button" class="btn" :disabled="!input.indirizzoEmail || !input.password || !input.confermaPassword">Registrati</button>
        </template>
        <router-link align="center" :to="'/login'">Hai già un account? Accedi</router-link>
      </form>
    </div>
  </main>
</template>

<script>
import ky from '../../network'
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import utils from '@/utils'
import SwitchSelect from '@/common/components/Switch'
export default {
    data(){
        return {
            input: {
                nome: '',
                cognome: '',
                indirizzoEmail : '',
                numeroTelefono: '',
                dataNascita:   '',
                password: '',
                confermaPassword: '',
                abilitaMarketing: true
            },
            error: '',
            step: 0,
        }
    },
    methods: {
        async registrazione(){
            try{
                if(this.input.dataNascita)this.input.dataNascita = utils.UTC(this.input.dataNascita);
                const r = await ky.post("/api/Login/Registrazione",{json: this.input}).json();
                if(r.status){
                    await this.$root.login(this.input.indirizzoEmail,this.input.password);
                }
            } catch(e){
                debugger;
                this.loading = false;
                let err = await e.response.json();
                console.log(JSON.stringify(err));
                this.$root.$emit("snackbar", { type: "error", message: err.error });
            }
        }
    },
    components: {
        DatePicker,
        SwitchSelect
    }
}
</script>

<style lang="scss" scoped>
#nope {
    pointer-events: none;
    margin: 2em 0;
    width: 100%;    
}
    .dangelo-login {
        max-height: initial;
        &.reg {
            justify-content: flex-start;
            form {
                height: 100%;
            }
        }
    }
</style>